import BlockVueComponent from '../../components/InputField'

export default class InputFieldBlock {
  type() {
    return 'inputfield'
  }

  icon() {
    return 'mdi-form-textbox'
  }

  name() {
    return 'Champ de saisi'
  }

  component() {
    return BlockVueComponent
  }

  settings() {
    return {
      fields: [
        {
          type: 'toggle',
          varname: 'required',
          label: 'Le champ est obligatoire'
        }
      ]
    }
  }
}
