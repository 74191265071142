import BlockVueComponent from '../../components/Paragraph'

export default class ParagraphBlock {
  type() {
    return 'paragraph'
  }

  icon() {
    return 'mdi-text'
  }

  name() {
    return 'Texte'
  }

  component() {
    return BlockVueComponent
  }

  settings() {
    return {
      fields: []
    }
  }
}
