<template>
  <v-row v-if="compEdit" class="ma-2">
    <v-col cols="12">
      <div
        v-if="
          !!['cards'].includes(
            $store.state.auth.company.themes['services'].id
          ) && !$store.state.auth.company.themes['services'].useFusion
        "
      >
        <v-switch
          v-model="compEdit.useColor"
          label="Utiliser une couleur de fond"
        />
      </div>
      <div
        v-if="
          !compEdit.useColor ||
          !!$store.state.auth.company.themes['services'].useFusion ||
          !['cards'].includes($store.state.auth.company.themes['services'].id)
        "
      >
        <images-selector
          v-model="compEdit.images"
          :per-line="3"
          :size="size"
          :updateData="updateData"
          :categories="images"
          :imageSizes="sizes"
        />
      </div>
      <div
        v-if="
          (!!compEdit.useColor ||
            !!$store.state.auth.company.themes['services'].useFusion) &&
          !!['cards'].includes($store.state.auth.company.themes['services'].id)
        "
        class="mt-3"
      >
        <v-color-picker
          v-model="compEdit.backgroundColor"
          :swatches="swatches"
          show-swatches
          mode="hexa"
          class="ma-auto"
        />
      </div>
    </v-col>
    <v-col cols="12">
      <file-explorer
        :images="images"
        :loading="loading"
        @uploadImages="uploadImages"
      />
    </v-col>
    <v-col cols="12">
      <multilang-text-field
        orientation="horizontal"
        v-model="compEdit.name"
        :label="$t('categories.categoryName')"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        class="pl-3"
        type="text"
        label="Slug"
        v-model="compEdit.slug"
        disabled
      />
    </v-col>
    <v-col cols="12">
      <v-select
        v-model="compEdit.tags"
        :items="tabExistingTags"
        multiple
        outlined
        label="Tags"
        :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
      >
        <template v-slot:selection="{ item }">
          <v-chip close @click:close="deleteTag(item)">
            {{ controli18n($i18n.locale, item) }}
          </v-chip>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item
            :class="{
              'grey lighten-2': compEdit.tags.find(
                (t) =>
                  controli18n($i18n.locale, t) ===
                  controli18n($i18n.locale, item)
              ),
            }"
            @click="
              !compEdit.tags.find(
                (t) =>
                  controli18n($i18n.locale, t) ===
                  controli18n($i18n.locale, item)
              )
                ? addTag(item)
                : deleteTag(item)
            "
          >
            <v-list-item-title>
              {{ controli18n($i18n.locale, item) }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:append-item>
          <v-divider class="my-2" />
          <v-list-item @click="dialogCreateTag = true">
            <v-list-item-title>
              {{ $t('categories.createNewTag') }}
              <v-icon
                style="border: 2px solid #777"
                class="rounded-circle ml-5"
              >
                mdi-plus
              </v-icon>
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>

      <v-dialog v-model="dialogCreateTag" width="500">
        <v-card v-if="dialogCreateTag" class="pa-5">
          <multilang-text-field
            label="Tag"
            v-model="tagToAdd"
            @keypress.enter="addTag(tagToAdd)"
          />
          <div class="d-flex align-center justify-space-around pt-3">
            <v-btn text color="red" @click="cancelAddTag">
              {{ $t('edit.cancel') }}
            </v-btn>
            <v-btn class="mx-5" color="green" dark @click="addTag(tagToAdd)">
              {{ $t('other.add') }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col cols="12">
      <multilang-textarea
        orientation="vertical"
        v-model="compEdit.description.short"
        :label="$t('edit.shortDescription')"
      />
    </v-col>
    <v-col cols="12">
      <multilang-textarea
        orientation="vertical"
        v-model="compEdit.description.long"
        :label="$t('edit.longDescription')"
      />
    </v-col>
  </v-row>
</template>

<script>
import MultilangTextarea from '@/components/helpers/multilangTextarea.vue'
import MultilangTextField from '@/components/helpers/multilangTextField.vue'
import ImagesSelector from '@/components/helpers/imagesSelector.vue'
import FileExplorer from '../../images/fileExplorer'
import { controli18n } from '@/helpers/langs'
import { langs } from '@/helpers/langs'
import imageSizes from '@/helpers/imageSizes'

export default {
  components: {
    FileExplorer,
    ImagesSelector,
    MultilangTextarea,
    MultilangTextField,
  },
  props: {
    edit: {
      type: Object,
      default: () => {},
    },
    updateData: {
      type: Array,
      default: () => [],
    },
    images: {
      type: Array,
      default: () => [],
    },
    tabCategories: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    tagToAdd: null,
    compEdit: null,
    dialogCreateTag: false,
    swatches: [
      ['#FFFFFF', '#000000', '#999999'],
      ['#FFFF00', '#FF0000', '#0000FF'],
      ['#00FF00', '#FF7F00', '#50026A'],
      ['#fa2c96', '#D4AF37', '#7B3F00'],
    ],
  }),
  computed: {
    tabExistingTags() {
      const tags = []
      this.tabCategories.forEach((cat) => {
        if (cat.tags) {
          cat.tags.forEach((tag) => {
            if (typeof tag === 'string') {
              if (!tags.find((t) => t === tag)) {
                const langsKeys = Object.keys(langs)

                const createTag = {}
                langsKeys.forEach((lang) => {
                  if (lang === this.$i18n.locale) {
                    createTag[lang] = tag
                  } else {
                    createTag[lang] = ''
                  }
                })

                tags.push(createTag)
              }
            } else if (
              !tags.find(
                (t) =>
                  this.controli18n(this.$i18n.locale, t) ===
                  this.controli18n(this.$i18n.locale, tag)
              )
            ) {
              tags.push(tag)
            }
          })
        }
      })

      if (this.compEdit.tags && this.compEdit.tags.length) {
        this.compEdit.tags.forEach((tag) => {
          if (typeof tag === 'string') {
            if (!tags.find((t) => t === tag)) {
              const langsKeys = Object.keys(langs)

              const createTag = {}
              langsKeys.forEach((lang) => {
                if (lang === this.$i18n.locale) {
                  createTag[lang] = tag
                } else {
                  createTag[lang] = ''
                }
              })

              tags.push(createTag)
            }
          } else if (
            !tags.find(
              (t) =>
                this.controli18n(this.$i18n.locale, t) ===
                this.controli18n(this.$i18n.locale, tag)
            )
          ) {
            tags.push(tag)
          }
        })
      }

      return tags
    },
    size() {
      return this.$store.state.auth.company &&
        this.$store.state.auth.company.themes &&
        this.$store.state.auth.company.themes.services &&
        this.$store.state.auth.company.themes.services.id
        ? imageSizes.categories[
            this.$store.state.auth.company.themes.services.id
          ]
        : imageSizes.categories.default
    },
    sizes() {
      return imageSizes.categories
    },
  },
  methods: {
    controli18n,
    uploadImages(images) {
      images.forEach((img) => {
        if (!this.edit.images.find((i) => i.url === img.url)) {
          this.edit.images.push(img)
        }
      })
      this.$emit('updateData', this.edit.images)
    },
    addTag(tag) {
      if (
        this.controli18n(this.$i18n.locale, tag) &&
        !this.compEdit.tags.find(
          (t) =>
            this.controli18n(this.$i18n.locale, t) ===
            this.controli18n(this.$i18n.locale, tag)
        )
      ) {
        this.compEdit.tags.push(tag)
      }
      this.dialogCreateTag = false
      this.tagToAdd = null
    },
    deleteTag(tag) {
      const index = this.compEdit.tags.findIndex(
        (t) =>
          t === tag ||
          controli18n(this.$i18n.locale, t) ===
            controli18n(this.$i18n.locale, tag)
      )
      if (index > -1) {
        this.compEdit.tags.splice(index, 1)
      }
    },
    cancelAddTag() {
      this.tagToAdd = null
      this.dialogCreateTag = false
    },
  },
  mounted() {
    this.compEdit = JSON.parse(JSON.stringify(this.edit))
    this.compEdit.tags.forEach((tag) => {
      if (typeof tag === 'string') {
        const index = this.compEdit.tags.findIndex((t) => t === tag)
        const langsKeys = Object.keys(langs)
        this.compEdit.tags[index] = {}

        langsKeys.forEach((lang) => {
          if (lang === this.$i18n.locale) {
            this.compEdit.tags[index][lang] = tag
          } else {
            this.compEdit.tags[index][lang] = ''
          }
        })
      }
    })
  },
  watch: {
    compEdit: {
      handler(val) {
        this.$emit('updateEdit', val)
      },
      deep: true,
    },
  },
}
</script>

<style></style>
