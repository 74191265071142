<template>
  <div class="editor">
    <v-card class="my-5" flat color="transparent">
      <div class="editor-content">
        <div
          v-for="(block, blockIndex) in content"
          :key="block.id"
          class="block"
          :class="editable ? 'bb' : ''"
        >
          <component
            :is="blocksList[block.type].component()"
            v-if="!editable && !isBlockHidden(blockIndex)"
            :block="block"
            :editable="isBlockEditionMode(block)"
            :fillable="fillable"
            class="px-6"
          />
          <v-row v-if="editable" class="d-flex align-center">
            <v-col cols="1">
              <div
                v-if="!isBlockEditionMode(block) && !blockEditing"
                class="d-flex align-center editor-block-actions dflex"
              >
                <v-btn icon small @click="editBlock(block)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>

                <v-speed-dial direction="right">
                  <template #activator>
                    <v-btn small icon>
                      <v-icon small> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                    fab
                    x-small
                    style="z-index: 10"
                    color="secondary"
                    @click="moveBlockUp(block)"
                  >
                    <v-icon x-small>mdi-arrow-up</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    x-small
                    style="z-index: 10"
                    color="secondary"
                    @click="moveBlockDown(block)"
                  >
                    <v-icon x-small>mdi-arrow-down</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    x-small
                    style="z-index: 10"
                    color="red"
                    dark
                    @click="deleteBlock(block)"
                  >
                    <v-icon x-small>mdi-delete</v-icon>
                  </v-btn>
                </v-speed-dial>
              </div>
              <div
                v-if="isBlockEditionMode(block)"
                class="d-flex align-center editor-block-actions dflex"
              >
                <v-btn
                  v-if="blocksList[block.type].settings().fields.length"
                  icon
                  small
                  @click="openBlockParams(block)"
                >
                  <v-icon small>mdi-cog</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="10">
              <v-card
                class="py-3 px-3 rounded-0"
                flat
                :class="isBlockHidden(blockIndex) ? 'block-hidden' : ''"
                style="position: relative"
              >
                <v-icon
                  v-if="
                    isBlockCanBeHidden(blockIndex) && isBlockHidden(blockIndex)
                  "
                  color="grey"
                  style="
                    position: absolute;
                    top: 5px;
                    left: -17px;
                    background-color: #fff;
                    border-radius: 100%;
                    padding: 5px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                  "
                  >mdi-eye-off</v-icon
                >
                <v-icon
                  v-if="
                    isBlockCanBeHidden(blockIndex) && !isBlockHidden(blockIndex)
                  "
                  color="blue"
                  style="
                    position: absolute;
                    top: 5px;
                    left: -17px;
                    background-color: #fff;
                    border-radius: 100%;
                    padding: 5px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                  "
                  >mdi-eye</v-icon
                >

                <component
                  :is="blocksList[block.type].component()"
                  :block="block"
                  :editable="isBlockEditionMode(block)"
                  :fillable="fillable"
                  class="px-6"
                />
              </v-card>
            </v-col>
            <v-col cols="1">
              <!--              <div-->
              <!--                class="d-flex align-center"-->
              <!--                v-if="!isBlockEditionMode(block) && !blockEditing"-->
              <!--              >-->
              <!--                <v-btn icon small color="red" @click="deleteBlock(block)">-->
              <!--                  <v-icon small>mdi-delete</v-icon>-->
              <!--                </v-btn>-->
              <!--              </div>-->
              <div v-if="isBlockEditionMode(block)" class="d-flex align-center">
                <v-btn icon small color="green" @click="validateEditBlock()">
                  <v-icon small>mdi-check</v-icon>
                </v-btn>
                <v-btn icon small color="red" @click="cancelEditBlock(block)">
                  <v-icon small>mdi-cancel</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <div
            v-if="editable && !blockEditing"
            class="editor-block-actions dblock text-center"
            style="position: relative; z-index: 999"
          >
            <v-btn
              color="primary"
              fab
              small
              style="position: absolute; top: -20px"
              @click="addBlockButton(blockIndex + 1)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div
        v-if="editable && !blockEditing && !content.length"
        class="editor-actions text-center py-4"
      >
        <v-btn color="primary" fab small @click="addBlockButton">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-if="editable" v-model="addBlockDialog" width="500">
      <v-card>
        <v-card-title> Ajouter un bloc </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="block in blocksList"
              :key="block.type()"
              cols="12"
              md="6"
            >
              <v-card
                block
                class="my-3 text-center py-6"
                @click="addBlock(block)"
              >
                <div class="mb-2">
                  <v-icon>
                    {{ block.icon() }}
                  </v-icon>
                </div>
                <div>
                  {{ block.name() }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="editable && blockSettings"
      :value="true"
      width="500"
      persistent
    >
      <v-card v-if="blockSettings">
        <v-card-title> Paramètres </v-card-title>
        <v-card-text>
          <div
            v-for="(field, fieldIndex) in blocksList[
              blockSettings.type
            ].settings().fields"
            :key="fieldIndex"
            class="setting"
          >
            <component
              :is="fieldsList[field.type]"
              :block="blockSettings"
              :field="field"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="blockSettings = null">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BlocksList from './blocks/schemas/blocks'
import FieldsList from './settings/settings'

export default {
  name: 'Editor',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    editable: {
      type: Boolean,
      default: false
    },
    fillable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: this.value,
      addBlockDialog: false,
      addBlockPosition: 0,
      blockEditing: null,
      blockSettings: null
    }
  },
  computed: {
    blocksList() {
      return BlocksList
    },
    fieldsList() {
      return FieldsList
    }
  },
  watch: {
    content: {
      handler() {
        this.$emit('input', this.content)
      },
      deep: true
    }
  },
  mounted() {
    document.addEventListener('keydown', event => {
      if (event.ctrlKey && event.keyCode === 13) {
        this.validateEditBlock()
      }
      if (event.ctrlKey && event.keyCode === 66) {
        this.addBlockButton()
      }
    })
  },
  methods: {
    generateID() {
      return (
        Date.now().toString(32) +
        Math.random()
          .toString(32)
          .substr(2)
      )
    },
    addBlockButton(position = 0) {
      if (!this.editable) {
        return
      }

      this.addBlockDialog = true
      this.addBlockPosition = position
    },
    addBlock(block) {
      if (!this.editable) {
        return
      }

      const newBlock = {
        id: this.generateID(),
        type: block.type(),
        data: {}
      }

      this.content.splice(this.addBlockPosition, 0, newBlock)
      this.addBlockDialog = false
    },
    deleteBlock(block) {
      if (!this.editable) {
        return
      }

      const blockIndex = this.content.findIndex(b => b.id === block.id)
      this.content.splice(blockIndex, 1)
    },
    moveBlockUp(block) {
      if (!this.editable) {
        return
      }

      const blockIndex = this.content.findIndex(b => b.id === block.id)
      const previousBlockIndex = blockIndex - 1

      if (previousBlockIndex < 0) {
        return
      }

      const copyContent = [...this.content]

      ;[copyContent[previousBlockIndex], copyContent[blockIndex]] = [
        copyContent[blockIndex],
        copyContent[previousBlockIndex]
      ]

      this.content = [...copyContent]
    },
    moveBlockDown(block) {
      if (!this.editable) {
        return
      }

      const blockIndex = this.content.findIndex(b => b.id === block.id)
      const nextBlockIndex = blockIndex + 1

      if (nextBlockIndex >= this.content.length) {
        return
      }

      const copyContent = [...this.content]

      ;[copyContent[blockIndex], copyContent[nextBlockIndex]] = [
        copyContent[nextBlockIndex],
        copyContent[blockIndex]
      ]

      this.content = [...copyContent]
    },
    isBlockEditionMode(block) {
      return this.blockEditing && this.blockEditing.id === block.id
    },
    editBlock(block) {
      if (!this.editable) {
        return
      }

      if (block) {
        this.blockEditing = JSON.parse(JSON.stringify(block))
      } else {
        this.blockEditing = null
      }
    },
    cancelEditBlock(block) {
      if (!this.editable) {
        return
      }

      const blockIndex = this.content.findIndex(b => b.id === block.id)

      this.$set(
        this.content,
        blockIndex,
        JSON.parse(JSON.stringify(this.blockEditing))
      )

      this.blockEditing = null
    },
    validateEditBlock() {
      if (!this.editable) {
        return
      }

      this.blockEditing = null
    },
    openBlockParams(block) {
      this.blockSettings = block
    },
    isBlockCanBeHidden(blockIndex) {
      if (blockIndex < 1) {
        return false
      }

      const previousBlock = this.content[blockIndex - 1]

      if (previousBlock.data.hideNextBlock) {
        return true
      }

      return false
    },
    isBlockHidden(blockIndex) {
      if (blockIndex < 1) {
        return false
      }

      let bI = 1

      let hidden = false

      while (blockIndex - bI >= 0) {
        const previousBlock = this.content[blockIndex - bI]

        if (!previousBlock.data.hideNextBlock) {
          break
        }

        if (
          previousBlock.data.hideNextBlock &&
          !previousBlock.data.hideNextBlockValue
        ) {
          hidden = true
          break
        }

        bI++
      }

      return hidden
    }
  }
}
</script>

<style scoped>
.bb {
  border-bottom: 1px dashed #eee;
}

.editor-block-actions {
  display: none !important;
}

.block:hover .editor-block-actions.dblock {
  display: block !important;
}

.block:hover .editor-block-actions.dflex {
  display: flex !important;
}

.block-hidden {
  background-color: #ccc;
}
</style>

<style>
.editor .block p {
  margin: 0;
}
</style>
