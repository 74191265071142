<template>
  <div>
    <RichTextEditor v-model="block.data.text" :editable="editable" />
  </div>
</template>

<script>
import RichTextEditor from '../../helpers/RichTextEditor'

export default {
  name: 'Paragraph',
  components: { RichTextEditor },
  props: {
    block: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (!this.block.data.text) {
      this.$set(this.block.data, 'text', 'Entrez un paragraphe.')
    }

    this.$set(this.block.data, 'isValid', true)
  }
}
</script>

<style scoped></style>
