<template>
  <div class="d-flex align-center flex-wrap">
    <div class="d-flex align-center" style="width: 100%">
      <strong
        v-if="blockData.data.required && (fillable || editable)"
        style="color: red; margin-right: 5px"
      >
        *
      </strong>
      <v-checkbox v-model="blockData.data.yes" :disabled="!fillable" /> Oui
      <v-checkbox
        v-model="blockData.data.no"
        :disabled="!fillable"
        class="ml-6"
      />
      Non
    </div>
    <transition name="fade_height">
      <v-text-field
        v-if="showPreciseField"
        v-model="blockData.data.precise"
        :disabled="!fillable"
        label="Précisez"
        class="ml-6"
      />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'YesNo',
  props: {
    block: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    fillable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      blockData: this.block
    }
  },
  computed: {
    showPreciseField() {
      if (!this.blockData.data.preciseField) {
        return false
      }

      if (
        this.blockData.data.preciseAppear === 'no' &&
        !this.blockData.data.no
      ) {
        return false
      }

      if (
        this.blockData.data.preciseAppear === 'yes' &&
        !this.blockData.data.yes
      ) {
        return false
      }

      return true
    }
  },
  watch: {
    'block.data.yes'(newVal) {
      if (newVal) {
        this.block.data.no = false
      }

      if (!this.block.data.required) {
        this.block.data.isValid = true
        return
      }

      if (!this.block.data.no && !newVal) {
        this.block.data.isValid = false
      } else {
        this.block.data.isValid = true
      }
    },
    'block.data.no'(newVal) {
      if (newVal) {
        this.block.data.yes = false
      }

      if (!this.block.data.required) {
        this.block.data.isValid = true
        return
      }

      if (!this.block.data.yes && !newVal) {
        this.block.data.isValid = false
      } else {
        this.block.data.isValid = true
      }
    }
  },
  created() {
    if (!this.block.data.yes) {
      this.$set(this.block.data, 'yes', false)
    }

    if (!this.block.data.no) {
      this.$set(this.block.data, 'no', false)
    }

    if (!this.block.data.precise) {
      this.$set(this.block.data, 'precise', '')
    }

    if (!this.block.data.preciseField) {
      this.$set(this.block.data, 'preciseField', false)
    }

    if (!this.block.data.preciseAppear) {
      this.$set(this.block.data, 'preciseAppear', 'both')
    }

    this.$set(this.block.data, 'isValid', !this.block.data.required)
  }
}
</script>

<style scoped>
.v-text-field {
  margin: 0 !important;
  padding: 0 !important;
}

@keyframes heightUp {
  from {
    height: 0;
  }
  to {
    height: 68px;
  }
}

@keyframes heightDown {
  from {
    height: 68px;
  }
  to {
    height: 0;
  }
}

.fade_height-enter-active {
  animation: heightUp 0.5s;
  transition: opacity 0.5s;
}

.fade_height-leave-active {
  animation: heightDown 0.5s;
  transition: opacity 0.5s;
}

.fade_height-enter {
  opacity: 0;
}

.fade_height-leave-to {
  opacity: 0;
}
</style>
