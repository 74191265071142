import BlockVueComponent from '../../components/Title'

export default class TitleBlock {
  type() {
    return 'title'
  }

  icon() {
    return 'mdi-format-title'
  }

  name() {
    return 'Titre'
  }

  component() {
    return BlockVueComponent
  }

  settings() {
    return {
      fields: []
    }
  }
}
