<template>
  <div>
    <v-switch v-model="block.data[field.varname]" :label="field.label" />
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    block: {
      type: Object,
      default: null
    },
    field: {
      type: Object,
      default: null
    }
  },
  created() {
    if (!this.block.data[this.field.varname]) {
      this.$set(this.block.data, this.field.varname, this.field.default)
    }
  }
}
</script>

<style scoped></style>
