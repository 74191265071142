<template>
  <div v-show="field.visible(block)">
    <v-select
      v-model="block.data[field.varname]"
      :label="field.label"
      :items="field.values"
    />
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    block: {
      type: Object,
      default: null
    },
    field: {
      type: Object,
      default: null
    }
  },
  created() {
    if (!this.block.data[this.field.varname]) {
      this.$set(this.block.data, this.field.varname, null)
    }
  }
}
</script>

<style scoped></style>
