<template>
  <div>
    <div v-if="editable">
      <client-only>
        <quillEditor v-model="valueData" />
      </client-only>
    </div>
    <span v-else v-html="valueData"> </span>
  </div>
</template>

<script>
// import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  components: {
    quillEditor: async () => {
      const { quillEditor } = await import('vue-quill-editor')
      return quillEditor
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value() {
      if (this.valueData === this.value) {
        return
      }

      this.valueData = this.value
    },
    valueData() {
      this.$emit('input', this.valueData)
    }
  },
  data() {
    return {
      valueData: this.value
    }
  }
}
</script>
