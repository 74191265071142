<template>
  <h2>
    <TextEditor v-model="block.data.text" :editable="editable" />
  </h2>
</template>

<script>
import TextEditor from '../../helpers/TextEditor'

export default {
  name: 'Paragraph',
  components: { TextEditor },
  props: {
    block: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (!this.block.data.text) {
      this.$set(this.block.data, 'text', 'Entrez un titre')
    }

    this.$set(this.block.data, 'isValid', true)
  }
}
</script>

<style scoped></style>
