<template>
  <div>
    <v-overlay
      class="d-flex justify-center align-center"
      v-if="loading"
      absolute
    >
      <v-progress-circular indeterminate />
    </v-overlay>

    <!--  HOME  -->
    <module-list-light
      v-if="
        !manageCategories && !articlesClassification && !showNewOnlineArticle
      "
      key="1"
    >
      <template v-slot:actionBlock>
        <v-btn-toggle v-model="selectedStatus" mandatory @change="search">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon :color="$icones['all'].color">
                  {{ $icones['all'].icon }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('articles.allArticles') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon :color="$icones['onLine'].color">
                  {{ $icones['onLine'].icon }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('articles.onlineArticles') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon :color="$icones['draft'].color">
                  {{ $icones['draft'].icon }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('articles.draftArticles') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon :color="$icones['offLine'].color">
                  {{ $icones['offLine'].icon }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('articles.articlesOffline') }}</span>
          </v-tooltip>
        </v-btn-toggle>

        <v-text-field
          v-model="selectedName"
          :label="$t('other.name')"
          background-color="white"
          outlined
          hide-details
          dense
          @keypress.enter="search"
        />

        <v-select
          v-model="selectedCategories"
          :items="tabNameCategories"
          :label="$t('database.categories')"
          background-color="white"
          outlined
          hide-details
          dense
          style="width: 250px"
          multiple
          class="customWidth"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{ item }}</span>
            <span v-if="index === 1">, ...</span>
          </template>
        </v-select>

        <v-text-field
          v-model="selectedPriceDuration"
          :label="$t('other.price') + ' / ' + $t('database.duration')"
          background-color="white"
          outlined
          hide-details
          dense
          @keypress.enter="search"
        />

        <v-select
          v-model="selectedTypes"
          :items="tabTypes"
          :label="$t('settings.refundManagement.type')"
          background-color="white"
          outlined
          hide-details
          dense
          style="width: 175px"
          multiple
          class="customWidth"
        />

        <v-btn dark @click="search">
          {{ $t('other.research') }}
        </v-btn>

        <v-btn
          v-if="getNewOnlineArticle.length && $vuetify.breakpoint.smAndDown"
          class="my-auto"
          dark
          dense
          @click="showNewOnlineArticle = true"
        >
          {{ $t('articles.latestArticlesPosted') }}
        </v-btn>
      </template>

      <template v-if="$vuetify.breakpoint.smAndDown" v-slot:actionSearch>
        <div class="d-flex">
          <v-icon @click="articlesClassification = true" class="mx-4">
            mdi-order-numeric-ascending
          </v-icon>

          <v-icon @click="manageCategories = true" class="mx-4">
            mdi-clipboard-list-outline
          </v-icon>
        </div>
      </template>

      <template v-else v-slot:manageContent>
        <div class="d-flex justify-end">
          <v-btn
            v-if="getNewOnlineArticle.length"
            class="my-auto"
            dark
            dense
            @click="showNewOnlineArticle = true"
          >
            {{ $t('articles.latestArticlesPosted') }}
          </v-btn>
          <v-spacer v-if="getNewOnlineArticle.length" />
          <div>
            <v-btn class="ma-2" dark @click="articlesClassification = true">
              {{ $t('database.articlesClassification') }}
            </v-btn>

            <v-btn class="ma-2" dark @click="manageCategories = true">
              {{ $t('articles.manageCategories') }}
            </v-btn>
          </div>
        </div>
      </template>

      <template v-slot:contentBlock>
        <v-card v-if="removedArticles.length">
          <v-data-table
            :headers="removedArticlesHeaders"
            :items="removedArticles"
            :items-per-page="9999999999"
            group-by="family"
            hide-default-footer
            class="data-table"
            mobile-breakpoint="1100"
            fixed-header
            height="200px"
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-row>
                <v-col cols="6" align-self="center">
                  <icon-display
                    v-if="!item.id"
                    :icon="$icones.offLine"
                    :small="false"
                    :tool-tip="$t('icons.putInDraft')"
                  />
                  <icon-display
                    v-else-if="item.draft"
                    :icon="$icones.draft"
                    :small="false"
                    :tool-tip="$t('other.upload')"
                  />
                  <icon-display
                    v-else
                    @actionClick="draftArticle(item)"
                    :icon="$icones.onLine"
                    :small="false"
                    :tool-tip="$t('icons.putInDraft')"
                  />
                </v-col>
                <v-col v-if="missingSettings(item)" cols="6">
                  <v-icon color="orange">mdi-alert</v-icon>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <span v-if="item.name">
                {{ controli18n($i18n.locale, item.name) }}
              </span>
              <span v-else>
                {{ item.artebeauteName }}
              </span>
            </template>
            <template v-slot:[`item.alertmsg`]="{}">
              <v-icon color="orange" left>mdi-alert</v-icon>
              <strong>Cet article à été supprimé d'ArteBeaute</strong>
            </template>

            <template
              v-slot:[`group.header`]="{ group, toggle, isOpen, items }"
            >
              <td :colspan="articlesHeaders.length + 1" class="grey darken-2">
                <div class="d-inline">
                  <v-btn icon @click="toggle">
                    <v-icon color="white" v-if="isOpen">mdi-chevron-up</v-icon>
                    <v-icon color="white" v-if="!isOpen">
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                  <span
                    v-if="items.length === 1"
                    style="color: white; font-weight: bold"
                  >
                    {{ group ? group : $t('settings.colors.others') }}
                    -
                    {{ items.length }}
                    {{ $t('database.articles') }}
                  </span>
                  <span v-else style="color: white; font-weight: bold">
                    {{ group ? group : $t('settings.colors.others') }} -
                    {{ items.length }}
                    {{ $t('database.articles') }}
                  </span>
                </div>
                <v-divider v-if="!isOpen" dark></v-divider>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-card>
          <v-data-table
            :headers="articlesHeaders"
            :items="articles"
            :items-per-page="itemsPerPage"
            :page.sync="pagination"
            group-by="family"
            :hide-default-footer="$vuetify.breakpoint.mdAndUp"
            @page-count="pageCount = $event"
            class="data-table"
            mobile-breakpoint="1100"
            fixed-header
            :height="removedArticles.length ? 'calc(50vh - 200px)' : '50vh'"
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-row>
                <v-col cols="6" align-self="center">
                  <icon-display
                    v-if="!item.id"
                    @actionClick="publishArticle(item)"
                    :icon="$icones.offLine"
                    :small="false"
                    :tool-tip="$t('icons.putInDraft')"
                  />
                  <icon-display
                    v-else-if="item.draft"
                    @actionClick="undraftArticle(item)"
                    :icon="$icones.draft"
                    :small="false"
                    :tool-tip="$t('other.upload')"
                  />
                  <icon-display
                    v-else
                    @actionClick="draftArticle(item)"
                    :icon="$icones.onLine"
                    :small="false"
                    :tool-tip="$t('icons.putInDraft')"
                  />
                </v-col>
                <v-col v-if="missingSettings(item)" cols="6">
                  <v-tooltip right>
                    <template v-slot:activator="{ attr, on }">
                      <span
                        v-bind="attr"
                        v-on="on"
                        class="red rounded-circle white--text py-1 px-2"
                        style="font-size: 0.8rem"
                      >
                        {{ missingSettings(item) }}
                      </span>
                    </template>
                    <span>{{ $t('icons.missingElements') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <span v-if="item.name">
                {{ controli18n($i18n.locale, item.name) }}
              </span>
              <span v-else>
                {{ item.artebeauteName }}
              </span>
            </template>
            <template v-slot:[`item.categories`]="{ item }">
              <template v-if="item.id">
                <v-select
                  v-model="item.categories"
                  :items="categories"
                  :placeholder="$t('other.add')"
                  item-text="name"
                  item-value="id"
                  multiple
                  hide-details
                  class="pa-0 hideBottom"
                  :outlined="item.categories && !item.categories.length"
                  :dense="item.categories && !item.categories.length"
                  :class="{ empty: item.categories && !item.categories.length }"
                >
                  <template
                    v-slot:selection="{ item: cat, index }"
                    v-if="item.categories && item.categories.length"
                  >
                    <v-chip v-if="index === 0">
                      {{ controli18n($i18n.locale, cat.name) }}
                    </v-chip>
                    <v-chip v-else-if="index === 1">...</v-chip>
                  </template>
                  <template v-slot:item="{ item: cat }">
                    <v-list-item
                      :class="{
                        'grey darken-2': isLinked(item, cat),
                      }"
                      :dark="isLinked(item, cat)"
                      @click="updateCategory(item, cat)"
                    >
                      <v-list-item-title>
                        <v-overlay
                          class="d-flex justify-center align-center"
                          v-if="linkLoading && catLoading.includes(cat.id)"
                          absolute
                        >
                          <v-progress-circular indeterminate />
                        </v-overlay>

                        {{ controli18n($i18n.locale, cat.name) }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
              </template>
            </template>
            <template v-slot:item.prices="{ item }">
              {{ parseFloat(item.prices).toFixed(2) }}€
            </template>
            <template v-slot:item.booker.duration="{ item }">
              {{ item.booker.duration ? item.booker.duration + 'min' : null }}
            </template>
            <template v-slot:[`item.art_type`]="{ item }">
              <v-chip
                x-small
                dark
                :color="stringToColour(getArticleType(item.art_type))"
              >
                {{ getArticleType(item.art_type) }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div v-if="item.id" class="content-icon-tab">
                <v-btn
                  @click="onEditArticle(item)"
                  class="btn-tab"
                  color="#433883"
                  outlined
                >
                  <v-icon>mdi-pencil</v-icon>
                  <span>{{ $t('other.edit') }}</span>
                </v-btn>

                <v-badge v-if="!itemHasShops(item)" color="red" dot>
                  <icon-display
                    :small="false"
                    @actionClick="onLinkArticleShop(item)"
                    :toolTip="$t('articles.shopsOfferingArticle')"
                    :icon="$icones.shop"
                  />
                </v-badge>

                <icon-display
                  v-else
                  :small="false"
                  @actionClick="onLinkArticleShop(item)"
                  :toolTip="$t('articles.shopsOfferingArticle')"
                  :icon="$icones.shop"
                />
              </div>
            </template>

            <template
              v-slot:[`group.header`]="{ group, toggle, isOpen, items }"
            >
              <td :colspan="articlesHeaders.length + 1" class="grey darken-2">
                <div class="d-inline">
                  <v-btn icon @click="toggle">
                    <v-icon color="white" v-if="isOpen">mdi-chevron-up</v-icon>
                    <v-icon color="white" v-if="!isOpen">
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                  <span
                    v-if="items.length === 1"
                    style="color: white; font-weight: bold"
                  >
                    {{ group ? group : $t('settings.colors.others') }}
                    -
                    {{ items.length }}
                    {{ $t('database.articles') }}
                  </span>
                  <span v-else style="color: white; font-weight: bold">
                    {{ group ? group : $t('settings.colors.others') }} -
                    {{ items.length }}
                    {{ $t('database.articles') }}
                  </span>
                </div>
                <v-divider v-if="!isOpen" dark></v-divider>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </template>

      <template v-slot:footerBlock>
        <div class="d-flex justify-center">
          <v-pagination
            v-if="pageCount > 1"
            v-model="pagination"
            :length="pageCount"
          />
        </div>
        <v-row no-gutters>
          <v-col cols="4" align-self="center">
            <div class="d-flex align-center justify-space-around">
              <div>
                <v-icon :color="$icones['onLine'].color">
                  {{ $icones['onLine'].icon }}
                </v-icon>
                {{ $t('icons.online') }}
              </div>
              <div>
                <v-icon :color="$icones['draft'].color">
                  {{ $icones['draft'].icon }}
                </v-icon>
                {{ $t('icons.draft') }}
              </div>
              <div>
                <v-icon :color="$icones['offLine'].color">
                  {{ $icones['offLine'].icon }}
                </v-icon>
                {{ $t('icons.offline') }}
              </div>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex justify-center mt-2">
              <div>
                <v-select
                  v-model="itemsPerPage"
                  :items="selectAbItemsPerPage"
                  background-color="white"
                  outlined
                  hide-details
                  dense
                  class="customWidth"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
    </module-list-light>

    <!--  NEW ARTICLES ONLINE  -->
    <module-list-light
      v-else-if="
        !manageCategories && !articlesClassification && showNewOnlineArticle
      "
      key="2"
    >
      <template v-slot:actionBlock>
        <v-text-field
          v-model="selectedName"
          :label="$t('other.name')"
          background-color="white"
          outlined
          hide-details
          dense
          @keypress.enter="search"
        />

        <v-select
          v-model="selectedCategories"
          :items="tabNameCategories"
          :label="$t('database.categories')"
          background-color="white"
          outlined
          hide-details
          dense
          style="width: 250px"
          multiple
          class="customWidth"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{ item }}</span>
            <span v-if="index === 1">, ...</span>
          </template>
        </v-select>

        <v-text-field
          v-model="selectedPriceDuration"
          :label="$t('other.price') + ' / ' + $t('database.duration')"
          background-color="white"
          outlined
          hide-details
          dense
          @keypress.enter="search"
        />

        <v-select
          v-model="selectedTypes"
          :items="tabTypes"
          :label="$t('settings.refundManagement.type')"
          background-color="white"
          outlined
          hide-details
          dense
          style="width: 175px"
          multiple
          class="customWidth"
        />

        <v-btn dark @click="search">
          {{ $t('other.research') }}
        </v-btn>

        <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          class="my-auto"
          dark
          dense
          @click="showNewOnlineArticle = false"
        >
          {{ $t('articles.showingAllArticles') }}
        </v-btn>
      </template>

      <template v-if="$vuetify.breakpoint.smAndDown" v-slot:actionSearch>
        <div class="d-flex">
          <v-icon @click="articlesClassification = true" class="mx-4">
            mdi-order-numeric-ascending
          </v-icon>

          <v-icon @click="manageCategories = true" class="mx-4">
            mdi-clipboard-list-outline
          </v-icon>
        </div>
      </template>

      <template v-else v-slot:manageContent>
        <div class="d-flex justify-end">
          <v-btn
            class="my-auto"
            dark
            dense
            @click="showNewOnlineArticle = false"
          >
            {{ $t('articles.showingAllArticles') }}
          </v-btn>
          <v-spacer />
          <div>
            <v-btn class="ma-2" dark @click="articlesClassification = true">
              {{ $t('database.articlesClassification') }}
            </v-btn>

            <v-btn class="ma-2" dark @click="manageCategories = true">
              {{ $t('articles.manageCategories') }}
            </v-btn>
          </div>
        </div>
      </template>

      <template v-slot:contentBlock>
        <v-card>
          <v-data-table
            :headers="articlesHeaders"
            :items="tabNewArticles"
            :items-per-page="itemsPerPage"
            :page.sync="pagination"
            :hide-default-footer="$vuetify.breakpoint.mdAndUp"
            @page-count="pageCount = $event"
            class="data-table"
            mobile-breakpoint="1100"
            fixed-header
            height="50vh"
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-row>
                <v-col cols="6" align-self="center">
                  <icon-display
                    v-if="!item.id"
                    @actionClick="publishArticle(item)"
                    :icon="$icones.offLine"
                    :small="false"
                    :tool-tip="$t('icons.putInDraft')"
                  />
                  <icon-display
                    v-else-if="item.draft"
                    @actionClick="undraftArticle(item)"
                    :icon="$icones.draft"
                    :small="false"
                    :tool-tip="$t('other.upload')"
                  />
                  <icon-display
                    v-else
                    @actionClick="draftArticle(item)"
                    :icon="$icones.onLine"
                    :small="false"
                    :tool-tip="$t('icons.putInDraft')"
                  />
                </v-col>
                <v-col v-if="missingSettings(item)" cols="6">
                  <v-tooltip right>
                    <template v-slot:activator="{ attr, on }">
                      <span
                        v-bind="attr"
                        v-on="on"
                        class="red rounded-circle white--text py-1 px-2"
                        style="font-size: 0.8rem"
                      >
                        {{ missingSettings(item) }}
                      </span>
                    </template>
                    <span>{{ $t('icons.missingElements') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <span v-if="item.name">
                {{ controli18n($i18n.locale, item.name) }}
              </span>
              <span v-else>
                {{ item.artebeauteName }}
              </span>
            </template>
            <template v-slot:[`item.categories`]="{ item }">
              <template v-if="item.id">
                <v-select
                  v-model="item.categories"
                  :items="categories"
                  :placeholder="$t('other.add')"
                  item-text="name"
                  item-value="id"
                  multiple
                  hide-details
                  class="pa-0 hideBottom"
                  :outlined="item.categories && !item.categories.length"
                  :dense="item.categories && !item.categories.length"
                  :class="{ empty: item.categories && !item.categories.length }"
                >
                  <template
                    v-slot:selection="{ item: cat, index }"
                    v-if="item.categories && item.categories.length"
                  >
                    <v-chip v-if="index === 0">
                      {{ controli18n($i18n.locale, cat.name) }}
                    </v-chip>
                    <v-chip v-else-if="index === 1">...</v-chip>
                  </template>
                  <template v-slot:item="{ item: cat }">
                    <v-list-item
                      :class="{
                        'grey darken-2': isLinked(item, cat),
                      }"
                      :dark="isLinked(item, cat)"
                      @click="updateCategory(item, cat)"
                    >
                      <v-list-item-title>
                        <v-overlay
                          class="d-flex justify-center align-center"
                          v-if="linkLoading && catLoading.includes(cat.id)"
                          absolute
                        >
                          <v-progress-circular indeterminate />
                        </v-overlay>

                        {{ controli18n($i18n.locale, cat.name) }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
              </template>
            </template>
            <template v-slot:item.prices="{ item }">
              {{ parseFloat(item.prices).toFixed(2) }}€
            </template>
            <template v-slot:item.booker.duration="{ item }">
              {{ item.booker.duration ? item.booker.duration + 'min' : null }}
            </template>
            <template v-slot:[`item.art_type`]="{ item }">
              <v-chip
                x-small
                dark
                :color="stringToColour(getArticleType(item.art_type))"
              >
                {{ getArticleType(item.art_type) }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div v-if="item.id" class="content-icon-tab">
                <v-btn
                  @click="onEditArticle(item)"
                  class="btn-tab"
                  color="#433883"
                  outlined
                >
                  <v-icon>mdi-pencil</v-icon>
                  <span>{{ $t('other.edit') }}</span>
                </v-btn>

                <v-badge v-if="!itemHasShops(item)" color="red" dot>
                  <icon-display
                    :small="false"
                    @actionClick="onLinkArticleShop(item)"
                    :toolTip="$t('articles.shopsOfferingArticle')"
                    :icon="$icones.shop"
                  />
                </v-badge>

                <icon-display
                  v-else
                  :small="false"
                  @actionClick="onLinkArticleShop(item)"
                  :toolTip="$t('articles.shopsOfferingArticle')"
                  :icon="$icones.shop"
                />
              </div>
            </template>
          </v-data-table>
        </v-card>
      </template>

      <template v-slot:footerBlock>
        <div class="d-flex justify-center">
          <v-pagination
            v-if="pageCount > 1"
            v-model="pagination"
            :length="pageCount"
          />
        </div>
        <v-row no-gutters>
          <v-col cols="4" align-self="center">
            <div class="d-flex align-center justify-space-around">
              <div>
                <v-icon :color="$icones['onLine'].color">
                  {{ $icones['onLine'].icon }}
                </v-icon>
                {{ $t('icons.online') }}
              </div>
              <div>
                <v-icon :color="$icones['draft'].color">
                  {{ $icones['draft'].icon }}
                </v-icon>
                {{ $t('icons.draft') }}
              </div>
              <div>
                <v-icon :color="$icones['offLine'].color">
                  {{ $icones['offLine'].icon }}
                </v-icon>
                {{ $t('icons.offline') }}
              </div>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex justify-center mt-2">
              <div>
                <v-select
                  v-model="itemsPerPage"
                  :items="selectAbItemsPerPage"
                  background-color="white"
                  outlined
                  hide-details
                  dense
                  class="customWidth"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
    </module-list-light>

    <!--  GESTION DES CATEGORIES  -->
    <module-list-light v-if="manageCategories" key="3">
      <template v-slot:actionBlock>
        <v-text-field
          v-model="selectedNameCat"
          :label="$t('other.name')"
          background-color="white"
          outlined
          hide-details
          dense
          @keypress.enter="searchCat"
        />

        <v-btn dark @click="searchCat">
          {{ $t('other.research') }}
        </v-btn>
      </template>

      <template v-slot:actionSearch>
        <v-btn dark @click="pageArticlesClassification">
          {{ $t('database.articlesClassification') }}
        </v-btn>
      </template>

      <template v-slot:contentBlock>
        <div>
          <categories-manager
            :categories="tabCategories"
            @update="emitUpdate"
            @back="manageCategories = false"
          />
        </div>
      </template>
    </module-list-light>

    <!--  CLASSIFICATION DES ARTICLES  -->
    <module-list-light v-if="articlesClassification">
      <template v-slot:actionSearch>
        <v-btn dark @click="pageManageCategories">
          {{ $t('articles.manageCategories') }}
        </v-btn>
      </template>

      <template v-slot:contentBlock>
        <div>
          <articles-classification
            :articles="onlineArticles"
            :categories="
              categories.filter(
                (cat) => cat.articles && cat.articles.length > 0
              )
            "
            @back="articlesClassification = false"
          />
        </div>
      </template>
    </module-list-light>

    <!--  EDIT PANEL ARTICLE  -->
    <edit-panel
      :display="editArticleModal"
      :title="
        editArticle
          ? $t('articles.articleTitle', {
              title: controli18n($i18n.locale, editArticle.name),
            })
          : $t('articles.editing')
      "
      @close="editArticleModal = false"
      @actionModal="actionForm"
    >
      <template v-slot:body>
        <edit-article
          :article="editArticle"
          :submit="submitAction"
          @isSubmit="isSubmit"
          v-if="editArticleModal"
          :key="editArticleModal.id"
        />
      </template>
    </edit-panel>

    <!--  DIALOG LINK SHOPS  -->
    <v-dialog width="950" v-model="shopsModal">
      <link-shops-to-article
        :link-shops-article="shopsArticle"
        @close="shopsModal = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { articleTypes } from '@/helpers/articles'
import EditPanel from '@/components/helpers/edit-panel'
import IconDisplay from '../../components/helpers/iconDisplay.vue'
import EditArticle from '@/components/database/articles/editArticle.vue'
import { controli18n } from '../../helpers/langs'
import icones from '../../helpers/icones'
import ModuleListLight from '../../components/general/moduleListLight'
import CategoriesManager from '../../components/database/categories/categoriesManager'
import ArticlesClassification from '../../components/database/articles/articlesClassification'
import LinkShopsToArticle from '../../components/database/articles/linkShopsToArticle'
import swal from 'sweetalert'
import { mapGetters } from 'vuex'

export default {
  components: {
    LinkShopsToArticle,
    CategoriesManager,
    ModuleListLight,
    ArticlesClassification,
    EditPanel,
    IconDisplay,
    EditArticle,
  },
  data() {
    return {
      loading: false,
      articles: [],
      itemsPerPage: 25,
      pagination: 1,
      pageCount: 0,
      editArticleModal: false,
      editArticle: null,
      shopsModal: false,
      shopsArticle: null,
      submitAction: false,
      tabStatus: ['all', 'onLine', 'offLine'],
      selectedStatus: 0,
      selectedName: null,
      selectedCategories: null,
      selectedPriceDuration: null,
      selectedTypes: null,
      manageCategories: false,
      articlesClassification: false,
      selectedNameCat: null,
      tabCategories: [],
      linkLoading: false,
      catLoading: [],
      showNewOnlineArticle: false,
      tabNewArticles: [],
    }
  },
  computed: {
    ...mapGetters({
      getNewOnlineArticle: 'articles/getNewOnlineArticle',
    }),
    categories() {
      return this.$store.getters['categories/getCategories']
    },
    fusionArticles: function () {
      return this.$store.getters['articles/fusionArticle']()
    },
    removedArticles: function () {
      return this.$store.getters['articles/removedArticles']()
    },
    allArticles() {
      return this.fusionArticles.filter((a) =>
        [1, 2, 4].includes(parseInt(a.art_type))
      )
    },
    onlineArticles() {
      return this.fusionArticles
        .filter((a) => [1, 2, 4].includes(parseInt(a.art_type)))
        .filter((a) => !!a.id && !a.draft)
    },
    offlineArticles() {
      return this.fusionArticles
        .filter((a) => [1, 2, 4].includes(parseInt(a.art_type)))
        .filter((a) => !a.id)
    },
    draftArticles() {
      return this.fusionArticles
        .filter((a) => [1, 2, 4].includes(parseInt(a.art_type)))
        .filter((a) => !!a.id && a.draft)
    },
    articlesHeaders() {
      return [
        {
          text: this.$t('database.tables.status'),
          value: 'status',
          width: '10%',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('other.name'),
          value: 'name',
          width: '20%',
          sortable: false,
          align: 'start',
        },
        {
          text: this.$t('database.categories'),
          value: 'categories',
          width: '20%',
          sortable: false,
          align: 'start',
        },
        {
          text: this.$t('other.price'),
          value: 'prices',
          width: '13%',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('database.duration'),
          value: 'booker.duration',
          width: '12%',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('settings.refundManagement.type'),
          value: 'art_type',
          width: '10%',
          sortable: false,
          align: 'center',
        },
        {
          text: '',
          value: 'actions',
          width: '15%',
          sortable: false,
        },
      ]
    },
    removedArticlesHeaders() {
      return [
        {
          text: this.$t('database.tables.status'),
          value: 'status',
          width: '10%',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('other.name'),
          value: 'name',
          width: '20%',
          sortable: false,
          align: 'start',
        },
        {
          text: '',
          value: 'alertmsg',
          width: '70%',
          sortable: false,
          align: 'start',
        },
      ]
    },
    tabNameCategories() {
      return [
        ...this.categories.map((cat) => {
          return controli18n(this.$i18n.locale, cat.name)
        }),
        this.$t('categories.withoutCategory'),
      ]
    },
    tabTypes() {
      return [
        {
          value: '1',
          text: this.$t('settings.appearance.services'),
        },
        {
          value: '2',
          text: 'Produits',
        },
        {
          value: '4',
          text: this.$t('articles.packs'),
        },
      ]
    },
    selectAbItemsPerPage() {
      return [
        10,
        25,
        50,
        100,
        {
          text: this.$t('other.all'),
          value: -1,
        },
      ]
    },
    $icones() {
      return icones().getIcones()
    },
    fusionShops: function () {
      return this.$store.getters['shop/fusionShop']({
        active: true,
        isOnline: true,
        onLineType: [],
      })
    },
  },
  async mounted() {
    try {
      this.articles = this.$store.getters['articles/fusionArticle']()

      await this.$store.dispatch('categories/fetchCategories', {})
    } catch (err) {
      console.log('err :>> ', err)
    }

    this.articles = this.allArticles
    this.tabCategories = this.categories
  },
  methods: {
    controli18n,
    getArticleType(type) {
      return articleTypes[type - 1]
    },
    stringToColour(str) {
      let hash = 0
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      let colour = '#'
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += ('00' + value.toString(16)).substr(-2)
      }
      return colour
    },
    onEditArticle(article) {
      this.editArticleModal = true
      this.editArticle = article
    },
    onLinkArticleShop(article) {
      this.shopsModal = true
      this.shopsArticle = article
    },
    isSubmit(value) {
      if (value) this.editArticleModal = false
      this.submitAction = false

      this.search()
    },
    actionForm(value) {
      if (value) {
        this.submitAction = true
      } else {
        this.editArticleModal = false
      }
    },
    search() {
      let articles = []

      if (!this.showNewOnlineArticle) {
        if (this.selectedStatus === 0) {
          articles = this.allArticles
        } else if (this.selectedStatus === 1) {
          articles = this.onlineArticles
        } else if (this.selectedStatus === 2) {
          articles = this.draftArticles
        } else if (this.selectedStatus === 3) {
          articles = this.offlineArticles
        }

        if (this.selectedName) {
          articles = articles.filter(
            (art) =>
              this.controli18n(this.$i18n.locale, art.name)
                .toUpperCase()
                .includes(this.selectedName.toUpperCase()) ||
              art.artebeauteName
                .toUpperCase()
                .includes(this.selectedName.toUpperCase())
          )
        }

        if (this.selectedCategories && this.selectedCategories.length) {
          let tabKeep = []

          articles.map((art) => {
            if (art.categories.length) {
              art.categories.map((cat) => {
                if (
                  this.selectedCategories.includes(
                    controli18n(this.$i18n.locale, cat.name)
                  )
                ) {
                  tabKeep.push(art)
                }
              })
            } else if (
              this.selectedCategories.includes(
                this.$t('categories.withoutCategory')
              )
            ) {
              tabKeep.push(art)
            }
          })

          articles = articles.filter((art) => tabKeep.includes(art))
        }

        if (this.selectedPriceDuration) {
          articles = articles.filter(
            (art) =>
              art.prices.toString().includes(this.selectedPriceDuration) ||
              art.booker.duration
                .toString()
                .includes(this.selectedPriceDuration)
          )
        }

        if (this.selectedTypes && this.selectedTypes.length) {
          articles = articles.filter((art) =>
            this.selectedTypes.includes(art.art_type.toString())
          )
        }

        this.articles = articles
      } else {
        articles = this.getNewOnlineArticle
      }
    },
    searchCat() {
      let categories = this.categories

      if (this.selectedNameCat) {
        categories = categories.filter((cat) =>
          controli18n(this.$i18n.locale, cat.name)
            .toUpperCase()
            .includes(this.selectedNameCat.toUpperCase())
        )
      }

      this.tabCategories = categories
    },
    updateCategory(art, cat) {
      if (this.isLinked(art, cat)) {
        this.unlinkCategoryToArticle(cat, art)
      } else {
        this.linkCategoryToArticle(cat, art)
      }
    },
    isLinked(art, cat) {
      return !!art.categories.some((c) => c.id === cat.id)
    },
    emitUpdate() {
      this.selectedCategories = null
      this.tabNewArticles = this.getNewOnlineArticle

      this.search()
    },
    pageManageCategories() {
      this.articlesClassification = false
      this.manageCategories = true
    },
    pageArticlesClassification() {
      this.articlesClassification = true
      this.manageCategories = false
    },
    missingSettings(item) {
      if (!item.id) {
        return
      }
      let missing = 0

      if (!controli18n(this.$i18n.locale, item.name)) {
        missing++
      }

      if (!item.slug) {
        missing++
      }

      if (item.categories && !item.categories.length) {
        missing++
      }

      let find = false
      for (let shop of this.fusionShops) {
        if (
          shop.articles &&
          shop.articles.find((a) => a.artebeauteId === item.artebeauteId)
        ) {
          find = true
          break
        }
      }
      if (!find) {
        missing++
      }

      if (missing > 0) {
        return missing
      }
    },
    itemHasShops(item) {
      for (let shop of this.fusionShops) {
        if (
          shop.articles &&
          shop.articles.find((a) => a.artebeauteId === item.artebeauteId)
        ) {
          return true
        }
      }
      return false
    },

    async publishArticle(item) {
      swal({
        title: this.$t('articles.articleEnable', {
          name: item.artebeauteName,
        }),
        buttons: [this.$t('icons.cancel'), this.$t('other.articleEnable')],
      }).then(async (ok) => {
        if (ok) {
          this.loading = true

          try {
            const abArticle = this.$store.getters['articles/getAbArticlesById'](
              item.artebeauteId
            )
            if (abArticle) {
              await this.$store.dispatch('articles/publishArticle', abArticle)
              this.emitUpdate()
              this.search()
            }
          } catch (err) {
            console.log('err :>> ', err)
          }

          this.loading = false

          setTimeout(() => {
            this.displayAlertLastArticle = false
          }, 5000)
        }
      })
    },
    async unpublishArticle(item) {
      swal({
        title: this.$t('articles.articlePutOffline', {
          name: this.controli18n(this.$i18n.locale, item.name),
        }),
        buttons: [this.$t('icons.cancel'), this.$t('other.putOffline')],
      }).then(async (ok) => {
        if (ok) {
          this.loading = true

          try {
            await this.$store.dispatch('articles/unpublishArticle', item)
            this.emitUpdate()
            this.search()
          } catch (err) {
            console.log('err :>> ', err)
          }

          this.loading = false
        }
      })
    },
    async undraftArticle(item) {
      swal({
        title: this.$t('articles.articleUpload', {
          name: this.controli18n(this.$i18n.locale, item.name),
        }),
        buttons: [this.$t('icons.cancel'), this.$t('other.upload')],
      }).then(async (ok) => {
        if (ok) {
          this.loading = true

          try {
            await this.$store.dispatch('articles/undraftArticle', item)
            this.emitUpdate()
            this.search()
          } catch (err) {
            console.log('err :>> ', err)
          }

          this.loading = false
        }
      })
    },
    async draftArticle(item) {
      swal({
        title: this.$t('articles.articlePutInDraft', {
          name: this.controli18n(this.$i18n.locale, item.name),
        }),
        buttons: [this.$t('icons.cancel'), this.$t('icons.putInDraft')],
      }).then(async (ok) => {
        if (ok) {
          this.loading = true

          try {
            await this.$store.dispatch('articles/draftArticle', item)
            this.emitUpdate()
            this.search()
          } catch (err) {
            console.log('err :>> ', err)
          }

          this.loading = false
        }
      })
    },
    async linkCategoryToArticle(category, article) {
      this.linkLoading = true
      this.catLoading.push(category.id)

      try {
        await this.$store.dispatch('categories/linkToArticle', {
          category,
          article,
        })
        this.emitUpdate()
        this.search()
      } catch (err) {
        console.log('err :>> ', err)
      }

      this.linkLoading = false
      this.catLoading.splice(
        this.catLoading.findIndex((c) => c === category.id)
      )
    },
    async unlinkCategoryToArticle(category, article) {
      this.linkLoading = true
      this.catLoading.push(category.id)

      try {
        await this.$store.dispatch('categories/unlinkFromArticle', {
          category,
          article,
        })
        this.emitUpdate()
        this.search()
      } catch (err) {
        console.log('err :>> ', err)
      }

      this.linkLoading = false
      this.catLoading.splice(
        this.catLoading.findIndex((c) => c === category.id)
      )
    },
  },
}
</script>

<style>
.customWidth input {
  display: none;
}

.hideBottom > .v-input__control > .v-input__slot:before {
  display: none;
}

.empty fieldset {
  border-color: red;
}
</style>

<style scoped>
.data-table >>> .v-data-table__divider {
  border-right: none;
}

.content-icon-tab {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.btn-tab >>> .v-btn__content {
  column-gap: 8px;
}

.btn-tab span {
  font-weight: 700;
  letter-spacing: normal;
}

.update-gg.v-btn {
  padding: 2px 5px;
  height: auto;
  margin-right: 5px;
}

.update-gg span {
  color: black;
  letter-spacing: normal;
  text-transform: none;
  margin-right: 10px;
  width: 70px;
  text-align: left;
}

.update-gg span.add-gg {
  color: rgb(150, 150, 150);
}

.cardTitle {
  font-size: 1.2rem !important;
}

.content-icon-tab {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
</style>
