<template>
  <div>
    <div>
      <v-text-field
        type="text"
        v-model="name"
        placeholder="Nom du consentement"
      />
    </div>
    <div>
      <PassEditor v-model="content" :editable="true" :fillable="false" />
    </div>
  </div>
</template>

<script>
import PassEditor from '@/packages/passeditor/Editor.vue'

export default {
  components: {
    PassEditor
  },
  data() {
    return {
      name: null,
      content: []
    }
  },
  methods: {
    getContent() {
      return {
        name: this.name,
        body: this.content
      }
    }
  }
}
</script>

<style scoped></style>
