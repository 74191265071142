<template>
  <div>
    <v-overlay
      class="d-flex justify-center align-center"
      v-if="loading"
      absolute
    >
      <v-progress-circular indeterminate />
    </v-overlay>

    <v-row
      class="ma-0"
      :class="$vuetify.breakpoint.mdAndUp ? 'mb-7' : undefined"
      align="center"
      :style="{
        float: $vuetify.breakpoint.mdAndUp ? 'left' : undefined,
        width: $vuetify.breakpoint.mdAndUp ? '100vw' : undefined,
      }"
    >
      <v-col cols="1" md="3">
        <v-icon color="dark" size="40" @click="$emit('back')">
          mdi-keyboard-backspace
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        md="6"
        class="text-uppercase text-center font-weight-bold"
        style="font-size: 1.2rem"
      >
        {{ $t('articles.manageCategories') }}
      </v-col>
    </v-row>
    <div
      style="position: sticky; z-index: 10; right: 10px"
      :style="{ top: $vuetify.breakpoint.mdAndUp ? '125px' : '100px' }"
      class="py-3 d-flex"
      :class="
        $vuetify.breakpoint.mdAndUp
          ? 'justify-end'
          : 'justify-space-between white mb-2'
      "
    >
      <v-btn
        color="green"
        :dark="hasChange"
        :disabled="!hasChange"
        @click="saveOrder"
      >
        {{ $t('settings.save') }}
      </v-btn>
      <v-btn color="primary" @click="createCat" class="ml-2">
        {{ $t('icons.new') }}
      </v-btn>
    </div>

    <template v-if="tabCategories.length">
      <div
        class="d-flex justify-center flex-wrap"
        style="width: 75vw; margin: auto"
      >
        <v-btn
          class="elevation-0 ma-2"
          rounded
          @click="sortArticles('name', 'asc')"
        >
          {{ $t('articles.nameAToZ') }}
        </v-btn>
        <v-btn
          class="elevation-0 ma-2"
          rounded
          @click="sortArticles('name', 'desc')"
        >
          {{ $t('articles.nameZToA') }}
        </v-btn>

        <v-btn
          class="elevation-0 ma-2"
          rounded
          @click="sortArticles('articles', 'asc')"
        >
          {{ $t('articles.numberArticleMinusToPlus') }}
        </v-btn>
        <v-btn
          class="elevation-0 ma-2"
          rounded
          @click="sortArticles('articles', 'desc')"
        >
          {{ $t('articles.numberArticlePlusToMinus') }}
        </v-btn>
      </div>

      <v-row class="mt-5 mx-auto" style="max-width: 700px">
        <v-col cols="2" md="1"></v-col>
        <v-col cols="6">{{ $t('other.name') }}</v-col>
        <v-col cols="3" class="text-center d-none d-md-inline-block">
          {{ $t('blog.articleNumber') }}
        </v-col>
      </v-row>

      <v-divider class="mx-auto" style="max-width: 700px" />

      <draggable
        v-model="tabCategories"
        class="mt-2 py-3"
        :class="{ 'px-5': $vuetify.breakpoint.mdAndDown }"
        @change="hasChange = true"
      >
        <v-row
          v-for="category in tabCategories"
          :key="category.id"
          class="my-4 mx-auto line elevation-1"
          style="max-width: 700px"
        >
          <v-col
            cols="2"
            md="1"
            class="d-flex justify-center align-center brown"
            style="
              border-right: solid 1px #333333;
              height: 100%;
              cursor: pointer;
            "
          >
            <v-icon dark>mdi-cursor-move</v-icon>
          </v-col>
          <v-col cols="7" md="6" class="font-weight-bold" align-self="center">
            {{ controli18n($i18n.locale, category.name) }}
          </v-col>
          <v-col
            cols="3"
            md="2"
            align-self="center"
            class="text-center d-none d-md-inline-block"
          >
            {{ category.articles ? category.articles.length : 0 }}
          </v-col>
          <v-col
            cols="3"
            class="d-flex justify-space-around text-right"
            align-self="center"
          >
            <icon-display
              :small="false"
              @actionClick="linkArts(category)"
              :icon="$icones.article"
            />
            <v-icon color="green" @click="editCat(category)">mdi-pencil</v-icon>
            <v-icon color="red darken-4" @click="deleteCat(category)">
              mdi-delete
            </v-icon>
          </v-col>
        </v-row>
      </draggable>
    </template>
    <template v-else>
      <div
        class="d-flex align-center justify-center rounded"
        style="border: 1px solid #333; height: 50px"
      >
        {{ $t('categories.haveNotCreatedYet') }}
      </div>
    </template>

    <!--  DIALOG LINK ARTICLES  -->
    <v-dialog v-model="linkArtsDialog" width="950">
      <link-articles-to-category
        :link-articles-category="category"
        :online-articles="onlineArticles"
        :loading="linkLoading"
        @close="linkArtsDialog = false"
        @publish="linkCategoryToArticle"
        @unpublish="unlinkCategoryToArticle"
        @publishAll="linkAllArticles"
        @linkArticlesWithoutCat="linkArticlesWithoutCat"
        @unpublishAll="unlinkAllArticles"
      />
    </v-dialog>

    <!--  DIALOG EDIT CATEGORY  -->
    <v-dialog v-model="editCategoryDialog" width="950">
      <v-card v-if="editCategoryDialog">
        <div>
          <v-card-title>{{ $t('categories.editCategory') }}</v-card-title>
          <v-divider />
        </div>

        <v-card-text class="px-10" style="height: 65vh; overflow: auto">
          <edit-category
            :edit="edit"
            :update-data="updateData"
            :images="images"
            :tab-categories="tabCategories"
            :loading="loadingBanque"
            @updateData="updateData = $event"
            @updateEdit="edit = $event"
          />

          <v-overlay
            class="d-flex justify-center align-center"
            v-if="saveLoading"
            absolute
            color="grey"
          >
            <v-progress-circular indeterminate />
          </v-overlay>
        </v-card-text>

        <div>
          <v-divider />
          <v-card-actions>
            <v-btn text @click="editCategoryDialog = false">
              {{ $t('icons.close') }}
            </v-btn>
            <v-spacer />
            <v-btn type="button" @click="save" color="success" tile>
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <!--  DIALOG CREATE CATEGORY  -->
    <v-dialog v-model="createCategoryDialog" width="950">
      <v-card v-if="createCategoryDialog">
        <div>
          <v-card-title>{{ $t('categories.createNewCategory') }}</v-card-title>
          <v-divider />
        </div>

        <v-card-text class="px-10" style="height: 65vh; overflow: auto">
          <edit-category
            :edit="edit"
            :update-data="updateData"
            :images="images"
            :tab-categories="tabCategories"
            :loading="loadingBanque"
            @updateData="updateData = $event"
            @updateEdit="edit = $event"
          />

          <v-overlay
            class="d-flex justify-center align-center"
            v-if="saveLoading"
            absolute
            color="grey"
          >
            <v-progress-circular indeterminate />
          </v-overlay>
        </v-card-text>

        <div>
          <v-divider />
          <v-card-actions>
            <v-btn text @click="createCategoryDialog = false">
              {{ $t('icons.close') }}
            </v-btn>
            <v-spacer />
            <v-btn type="button" @click="create" color="success" tile>
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { controli18n } from '../../../helpers/langs'
import icones from '../../../helpers/icones'
import IconDisplay from '../../helpers/iconDisplay'
import uploadsHelpers from '@/helpers/uploads'
import swal from 'sweetalert'
import slugify from 'slugify'
import draggable from 'vuedraggable'
import { ArteBeauteSDK } from '../../../packages/artebeaute-sdk/src'
import banqueImages from '@/helpers/banqueImages'
import LinkArticlesToCategory from './linkArticlesToCategory'
import EditCategory from './editCategory'

export default {
  name: 'categoriesManager',
  components: {
    EditCategory,
    LinkArticlesToCategory,
    IconDisplay,
    draggable,
  },
  props: {
    categories: {
      type: Array,
      default: () => null,
    },
  },
  data: () => {
    return {
      tabCategories: [],
      category: null,
      linkArtsDialog: false,
      editCategoryDialog: false,
      createCategoryDialog: false,
      loading: false,
      linkLoading: false,
      saveLoading: false,
      artsFamily: [],
      edit: {},
      hasChange: false,
      updateData: [],
      images: [],
      loadingBanque: false,
    }
  },
  computed: {
    fusionArticles: function () {
      return this.$store.getters['articles/fusionArticle']()
    },
    onlineArticles() {
      return this.fusionArticles
        .filter((a) => [1, 2, 4].includes(parseInt(a.art_type)))
        .filter((a) => !!a.id)
    },
    $icones() {
      return icones().getIcones()
    },
    nickname() {
      return this.$store.state.auth.company.nickname
    },
  },
  methods: {
    controli18n,
    createCat() {
      this.edit = {
        description: {},
        name: null,
        slug: null,
        images: [],
        tags: [],
        useColor: false,
        backgroundColor: '',
      }

      this.createCategoryDialog = true
    },
    linkArts(cat) {
      this.category = cat
      this.linkArtsDialog = true
    },
    isOnline(art) {
      return !!this.category.articles.some((a) => a.id === art.id)
    },
    editCat(cat) {
      this.category = cat

      this.edit = {
        useColor: cat.useColor,
        backgroundColor: cat.backgroundColor,
        description: cat.description,
        name: cat.name,
        slug: cat.slug,
        images: [],
        tags: cat.tags ? cat.tags : [],
      }

      if (cat.images && cat.images.length) {
        this.edit.images = cat.images
      }

      this.editCategoryDialog = true
    },

    sortArticles(by, order) {
      this.tabCategories = JSON.parse(JSON.stringify(this.tabCategories)).sort(
        (a, b) => {
          if (by === 'name') {
            if (order === 'asc') {
              return this.controli18n(this.$i18n.locale, a.name).toUpperCase() <
                this.controli18n(this.$i18n.locale, b.name).toUpperCase()
                ? -1
                : 1
            } else if (order === 'desc') {
              return this.controli18n(this.$i18n.locale, b.name).toUpperCase() <
                this.controli18n(this.$i18n.locale, a.name).toUpperCase()
                ? -1
                : 1
            }
          } else if (by === 'articles') {
            if (order === 'asc') {
              return a.articles.length - b.articles.length
            } else if (order === 'desc') {
              return b.articles.length - a.articles.length
            }
          }
        }
      )
      this.tabCategories = JSON.parse(JSON.stringify(this.tabCategories)).map(
        (a, i) => ({
          ...a,
          order: i,
        })
      )
      this.hasChange = true
    },
    async saveOrder() {
      this.loading = true

      const sortData = this.tabCategories.map((a, i) => ({
        order: i,
        id: a.id,
      }))

      await this.$store.dispatch('categories/updateOrders', { data: sortData })

      this.loading = false
      this.hasChange = false
    },

    async save() {
      this.saveLoading = true

      let imagesUrl = []

      try {
        for (let i = 0; i < this.edit.images.length; i++) {
          let exist = false
          this.images.map((img) => {
            img.src.forEach((s) => {
              if (s === this.edit.images[i].url) {
                exist = true
              }
            })
          })

          if (this.edit.images[i].new && !exist) {
            const image = this.edit.images[i].blob
            // const imageLazy = this.images[i].blobLazy
            const fileName = uploadsHelpers.randomName()

            /*
            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              'admin/categories',
              fileName
            )

            const lazyImageUrl = await uploadsHelpers.uploadSingleBase64(
              imageLazy,
              'admin/categories',
              fileName + '-lazy'
            )*/

            const directory = this.edit.images[i].category
              ? 'imagekit/eden/bank/categories/' + this.edit.images[i].category
              : 'imagekit/eden/categories'

            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              directory,
              fileName,
              this.nickname
            )

            imagesUrl.push({
              url: imageUrl,
              description: this.edit.images[i].description,
              alt: this.edit.images[i].alt,
            })
          } else {
            imagesUrl.push(this.edit.images[i])
          }
        }
      } catch (err) {
        console.log(err)
        return {
          error: true,
          message: this.$t('settings.anErrorOccuredImages'),
        }
      }

      const result = await this.$store.dispatch('categories/updateCategory', {
        id: this.category.id,
        data: {
          name: this.edit.name,
          useColor: this.edit.useColor,
          backgroundColor: this.edit.backgroundColor
            ? this.edit.backgroundColor
            : null,
          description: this.edit.description,
          slug: this.edit.slug,
          images: imagesUrl,
          tags: this.edit.tags,
        },
      })

      if (!result) {
        await swal({
          title: this.$t('categories.unableUpdateCategory'),
          icon: 'error',
        })
      } else {
        this.$emit('update')
        this.editCategoryDialog = false
      }

      this.saveLoading = false
    },
    async deleteCat(cat) {
      swal({
        title: this.$t('articles.areYouSureDeleteCategory', {
          category: this.controli18n(this.$i18n.locale, cat.name),
        }),
        buttons: [this.$t('icons.cancel'), this.$t('icons.delete')],
      }).then(async (willDelete) => {
        if (willDelete) {
          this.loading = true
          try {
            await this.$store.dispatch('categories/deleteCategory', {
              id: cat.id,
            })
            this.$emit('update')
            this.editCategoryDialog = false
          } catch (err) {
            console.log('err:>>', err)
            swal({
              title: this.$t('categories.unableRemoveCategory'),
              icon: 'error',
            })
          }
          this.loading = false
        }
      })
    },
    async create() {
      this.saveLoading = true

      let imagesUrl = []

      try {
        for (let i = 0; i < this.edit.images.length; i++) {
          let exist = false
          this.images.map((img) => {
            img.src.forEach((s) => {
              if (s === this.edit.images[i].url) {
                exist = true
              }
            })
          })

          if (this.edit.images[i].new && !exist) {
            const image = this.edit.images[i].blob
            // const imageLazy = this.images[i].blobLazy
            const fileName = uploadsHelpers.randomName()

            /*
            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              'admin/categories',
              fileName
            )

            const lazyImageUrl = await uploadsHelpers.uploadSingleBase64(
              imageLazy,
              'admin/categories',
              fileName + '-lazy'
            )*/

            const directory = this.edit.images[i].category
              ? 'imagekit/eden/bank/categories/' + this.edit.images[i].category
              : 'imagekit/eden/categories'

            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              directory,
              fileName,
              this.nickname
            )

            imagesUrl.push({
              url: imageUrl,
              description: this.edit.images[i].description,
              alt: this.edit.images[i].alt,
            })
          } else {
            imagesUrl.push(this.edit.images[i])
          }
        }
      } catch (err) {
        console.log(err)
        return {
          error: true,
          message: this.$t('settings.anErrorOccuredImages'),
        }
      }

      const result = await this.$store.dispatch('categories/createCategory', {
        name: this.edit.name,
        description: this.edit.description,
        slug: slugify(this.edit.slug, {
          strict: true,
        }).toLowerCase(),
        images: imagesUrl,
        tags: this.edit.tags,
      })

      if (!result) {
        swal({
          title: this.$t('categories.unableToCreateCategory'),
          icon: 'error',
        })
      } else {
        this.$emit('update')
        this.createCategoryDialog = false
      }

      this.saveLoading = false
    },

    async linkCategoryToArticle(article) {
      this.linkLoading = true

      const response = await this.$store.dispatch('categories/linkToArticle', {
        category: this.category,
        article,
      })

      if (response) {
        this.$emit('update')
      }

      this.linkLoading = false
    },
    async unlinkCategoryToArticle(article) {
      this.linkLoading = true

      const response = await this.$store.dispatch(
        'categories/unlinkFromArticle',
        {
          category: this.category,
          article,
        }
      )

      if (response) {
        this.$emit('update')
      }

      this.linkLoading = false
    },

    async linkAllArticles() {
      this.linkLoading = true

      await Promise.all(
        this.onlineArticles.map(async (art) => {
          await this.$store.dispatch('categories/linkToArticle', {
            category: this.category,
            article: art,
          })
        })
      )

      const response = await ArteBeauteSDK.Categories.getAllCategories()
      this.tabCategories = response.categories
      this.tabCategories = JSON.parse(JSON.stringify(this.tabCategories)).sort(
        (a, b) => a.order - b.order
      )
      const index = this.tabCategories.findIndex(
        (c) => c.id === this.category.id
      )
      this.category = this.tabCategories[index]

      this.$emit('update')
      this.linkLoading = false
    },
    async linkArticlesWithoutCat() {
      this.linkLoading = true

      await Promise.all(
        this.onlineArticles.map(async (art) => {
          if (!art.categories || art.categories.length === 0)
            await this.$store.dispatch('categories/linkToArticle', {
              category: this.category,
              article: art,
            })
        })
      )

      const response = await ArteBeauteSDK.Categories.getAllCategories()
      this.tabCategories = response.categories
      this.tabCategories = JSON.parse(JSON.stringify(this.tabCategories)).sort(
        (a, b) => a.order - b.order
      )
      const index = this.tabCategories.findIndex(
        (c) => c.id === this.category.id
      )
      this.category = this.tabCategories[index]

      this.$emit('update')
      this.linkLoading = false
    },
    async unlinkAllArticles() {
      this.linkLoading = true

      await Promise.all(
        this.onlineArticles.map(async (art) => {
          await this.$store.dispatch('categories/unlinkFromArticle', {
            category: this.category,
            article: art,
          })
        })
      )

      const response = await ArteBeauteSDK.Categories.getAllCategories()
      this.tabCategories = response.categories
      this.tabCategories = JSON.parse(JSON.stringify(this.tabCategories)).sort(
        (a, b) => a.order - b.order
      )
      const index = this.tabCategories.findIndex(
        (c) => c.id === this.category.id
      )
      this.category = this.tabCategories[index]

      this.$emit('update')
      this.linkLoading = false
    },
  },
  async mounted() {
    this.tabCategories = JSON.parse(JSON.stringify(this.categories)).sort(
      (a, b) => a.order - b.order
    )

    this.onlineArticles.map((art) => {
      if (!this.artsFamily.includes(art.family)) {
        this.artsFamily.push(art.family)
      }
    })

    this.loadingBanque = true
    await banqueImages.getCategories().then((res) => {
      this.images = res
    })
    this.loadingBanque = false

    let ourImages,
      yourImages = []
    this.loadingBanque = true
    await banqueImages.getCategories().then((res) => {
      ourImages = res
    })
    await banqueImages.getThem(this.nickname, 'categories').then((res) => {
      yourImages = res
    })
    yourImages.forEach((y) => {
      if (ourImages.find((o) => o.title === y.title)) {
        const src = ourImages.find((o) => o.title === y.title).src
        src.push(...y.src)
      } else {
        ourImages.push(y)
      }
    })
    ourImages.sort((a, b) => {
      if (a.title < b.title) return -1
      else if (b.title < a.title) return 1
      return 0
    })
    this.images = ourImages
    this.loadingBanque = false
  },
  watch: {
    'edit.name': {
      handler: function (val) {
        if (val) {
          this.edit.slug = slugify(val.fr, { strict: true }).toLowerCase()
        }
      },
      deep: true,
    },

    categories() {
      this.tabCategories = JSON.parse(JSON.stringify(this.categories)).sort(
        (a, b) => a.order - b.order
      )

      if (this.category) {
        const index = this.categories.findIndex(
          (c) => c.id === this.category.id
        )
        this.category = this.categories[index]
      }
    },

    tabCategories(val) {
      val.map((c) => {
        if (!c.articles) {
          c.articles = []
        }
      })
    },

    editCategoryDialog() {
      this.tagToAdd = null
    },
  },
}
</script>

<style scoped>
.line {
  border: 1px solid #333333;
  border-radius: 5px;
  background-color: #fff;
  height: 75px;
}
</style>
