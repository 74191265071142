<template>
  <div>
    <v-overlay
      class="d-flex justify-center align-center"
      v-if="loading"
      absolute
    >
      <v-progress-circular indeterminate />
    </v-overlay>

    <v-row
      class="ma-0"
      :class="$vuetify.breakpoint.mdAndUp ? 'mb-7' : undefined"
      align="center"
      :style="{
        float: $vuetify.breakpoint.mdAndUp ? 'left' : undefined,
        width: $vuetify.breakpoint.mdAndUp ? '100vw' : undefined
      }"
    >
      <v-col cols="1" md="3">
        <v-icon color="dark" size="40" @click="$emit('back')">
          mdi-keyboard-backspace
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        md="6"
        class="text-uppercase text-center font-weight-bold"
        style="font-size: 1.2rem"
      >
        {{ $t('database.articlesClassification') }}
      </v-col>
    </v-row>
    <div
      style="position: sticky; z-index: 10; right: 10px"
      :style="{ top: $vuetify.breakpoint.mdAndUp ? '125px' : '100px' }"
      class="py-3 d-flex"
      :class="
        $vuetify.breakpoint.mdAndUp
          ? 'justify-end mb-7'
          : 'justify-center white mb-2'
      "
    >
      <v-btn
        color="green"
        :dark="hasChange"
        :disabled="!hasChange"
        @click="save"
      >
        {{ $t('settings.save') }}
      </v-btn>
    </div>

    <div>
      <v-select
        v-model="selectedCategory"
        :items="tabCategories"
        item-value="id"
        style="width: 500px; margin: auto"
        @change="setArticles"
        outlined
        :label="$t('categories.categoryToSort')"
      >
        <template v-slot:selection="{ item }">
          {{ controli18n($i18n.locale, item.name) }}
        </template>
        <template v-slot:item="{ item }">
          {{ controli18n($i18n.locale, item.name) }}
        </template>
      </v-select>
    </div>

    <div
      class="d-flex justify-center flex-wrap"
      style="width: 75vw; margin: auto"
    >
      <v-btn
        class="elevation-0 ma-2"
        rounded
        @click="sortArticles('name', 'asc')"
      >
        {{ $t('articles.nameAToZ') }}
      </v-btn>
      <v-btn
        class="elevation-0 ma-2"
        rounded
        @click="sortArticles('name', 'desc')"
      >
        {{ $t('articles.nameZToA') }}
      </v-btn>

      <v-btn
        class="elevation-0 ma-2"
        rounded
        @click="sortArticles('price', 'asc')"
      >
        {{ $t('articles.priceMinusToPlus') }}
      </v-btn>
      <v-btn
        class="elevation-0 ma-2"
        rounded
        @click="sortArticles('price', 'desc')"
      >
        {{ $t('articles.pricePlusToMinus') }}
      </v-btn>

      <v-btn
        class="elevation-0 ma-2"
        rounded
        @click="sortArticles('duration', 'asc')"
      >
        {{ $t('articles.durationMinusToPlus') }}
      </v-btn>
      <v-btn
        class="elevation-0 ma-2"
        rounded
        @click="sortArticles('duration', 'desc')"
      >
        {{ $t('articles.durationPlusToMinus') }}
      </v-btn>
    </div>

    <v-row class="mt-5 mx-auto" style="max-width: 700px">
      <v-col cols="2" md="1"></v-col>
      <v-col cols="6" md="5">{{ $t('other.name') }}</v-col>
      <v-col cols="4" md="3" class="text-center">{{ $t('other.price') }}</v-col>
      <v-col cols="3" class="text-center d-none d-md-inline-block">{{
        $t('database.duration')
      }}</v-col>
    </v-row>

    <v-divider class="mx-auto" style="max-width: 700px" />

    <draggable
      v-model="tabArticles"
      class="mt-2 py-3"
      :class="{ 'px-5': $vuetify.breakpoint.mdAndDown }"
      @change="hasChange = true"
    >
      <v-row
        v-for="article in tabArticles"
        :key="article.id"
        class="my-4 mx-auto line elevation-1"
        style="max-width: 700px;"
      >
        <v-col
          cols="2"
          md="1"
          class="d-flex justify-center align-center brown"
          style="border-right: solid 1px #333333; height: 100%; cursor: pointer"
        >
          <v-icon dark>mdi-cursor-move</v-icon>
        </v-col>
        <v-col cols="7" md="5" class="font-weight-bold" align-self="center">
          {{ controli18n($i18n.locale, article.name) }}
        </v-col>
        <v-col cols="3" md="3" class="text-center" align-self="center">
          {{ parseFloat(article.prices.toFixed(2)) }}€
        </v-col>
        <v-col
          cols="2"
          md="3"
          class="text-center d-none d-md-inline-block"
          align-self="center"
        >
          {{ article.booker.duration }}min
        </v-col>
      </v-row>
    </draggable>
  </div>
</template>

<script>
import { controli18n } from '../../../helpers/langs'
import { ArteBeauteSDK } from '@/packages/artebeaute-sdk/src'
import draggable from 'vuedraggable'

export default {
  name: 'articlesClassification',
  components: {
    draggable
  },
  props: {
    articles: {
      type: Array,
      default: () => null
    },
    categories: {
      type: Array,
      default: () => null
    }
  },
  data: () => {
    return {
      selectedCategory: null,
      tabArticles: [],
      tabCategories: [],
      loading: false,
      hasChange: false
    }
  },
  methods: {
    controli18n,
    setArticles() {
      const articles = []
      this.articles.map(art => {
        if (
          art.categories.length &&
          art.categories.some(cat => cat.id === this.selectedCategory)
        ) {
          articles.push(art)
        }
      })

      this.tabArticles = JSON.parse(JSON.stringify(articles)).sort(
        (a, b) => a.order - b.order
      )

      this.hasChange = false
    },
    sortArticles(by, order) {
      this.tabArticles = JSON.parse(JSON.stringify(this.tabArticles)).sort(
        (a, b) => {
          if (by === 'price') {
            if (order === 'asc') {
              return a.prices - b.prices
            } else if (order === 'desc') {
              return b.prices - a.prices
            }
          } else if (by === 'name') {
            if (order === 'asc') {
              return this.controli18n(this.$i18n.locale, a.name).toUpperCase() <
                this.controli18n(this.$i18n.locale, b.name).toUpperCase()
                ? -1
                : 1
            } else if (order === 'desc') {
              return this.controli18n(this.$i18n.locale, b.name).toUpperCase() <
                this.controli18n(this.$i18n.locale, a.name).toUpperCase()
                ? -1
                : 1
            }
          } else if (by === 'duration') {
            if (order === 'asc') {
              return a.booker.duration - b.booker.duration
            } else if (order === 'desc') {
              return b.booker.duration - a.booker.duration
            }
          }
        }
      )
      this.tabArticles = JSON.parse(JSON.stringify(this.tabArticles)).map(
        (a, i) => ({
          ...a,
          order: i
        })
      )
      this.hasChange = true
    },
    async save() {
      this.loading = true

      const sortData = this.tabArticles.map((a, i) => ({
        order: i,
        id: a.id
      }))

      await ArteBeauteSDK.Articles.updateOrders(sortData)

      this.$store.commit('articles/SORT_ARTICLES', sortData)

      this.loading = false
      this.hasChange = false
    }
  },
  mounted() {
    this.tabCategories = this.categories.sort((a, b) => a.order - b.order)
    this.selectedCategory = this.tabCategories[0].id
    this.setArticles()
  }
}
</script>

<style scoped>
.line {
  border: 1px solid #333333;
  border-radius: 5px;
  background-color: #fff;
  height: 75px;
}
</style>
