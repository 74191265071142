import BlockVueComponent from '../../components/Checkbox'

export default class CheckboxBlock {
  type() {
    return 'checkbox'
  }

  icon() {
    return 'mdi-check-box-outline'
  }

  name() {
    return 'Case à cocher'
  }

  component() {
    return BlockVueComponent
  }

  settings() {
    return {
      fields: [
        {
          type: 'toggle',
          varname: 'hideNextBlock',
          label: 'Cacher le prochain bloc si non coché'
        },
        {
          type: 'toggle',
          varname: 'required',
          label: 'Le champ est obligatoire'
        }
      ]
    }
  }
}
