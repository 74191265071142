import BlockVueComponent from '../../components/YesNo'

export default class YesNoBlock {
  type() {
    return 'yesno'
  }

  icon() {
    return 'mdi-check'
  }

  name() {
    return 'Oui / Non'
  }

  component() {
    return BlockVueComponent
  }

  settings() {
    return {
      fields: [
        {
          type: 'toggle',
          varname: 'required',
          label: 'Le champ est obligatoire'
        },
        {
          type: 'toggle',
          varname: 'preciseField',
          label: 'Ajouter un champs "Précisez"'
        },
        {
          type: 'select',
          varname: 'preciseAppear',
          label: 'Afficher le champs sur',
          default: 'yes',
          values: [
            {
              value: 'no',
              text: 'Non'
            },
            {
              value: 'yes',
              text: 'Oui'
            },
            {
              value: 'both',
              text: 'Les deux'
            }
          ],
          visible: block => block.data.preciseField
        },
        {
          type: 'toggle',
          varname: 'blockIf',
          label: 'Bloquer la validation sur condition'
        },
        {
          type: 'select',
          varname: 'blockIfCondition',
          label: 'Bloquer la validation sur',
          default: 'yes',
          values: [
            {
              value: 'no',
              text: 'Non'
            },
            {
              value: 'yes',
              text: 'Oui'
            },
            {
              value: 'both',
              text: 'Les deux'
            }
          ],
          visible: block => block.data.blockIf
        }
      ]
    }
  }
}
