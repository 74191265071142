<template>
  <span
    ref="editable"
    :contenteditable="editable"
    v-on="listeners"
    :class="{ 'content-editable': editable }"
    placeholder="Entrer un texte"
    @keydown.enter.prevent
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newVal) {
      if (this.$refs.editable.textContent === newVal) {
        return
      }

      this.$refs.editable.textContent = newVal
    }
  },
  computed: {
    listeners() {
      return { ...this.$listeners, input: this.onInput }
    }
  },
  mounted() {
    this.$refs.editable.textContent = this.value
  },
  methods: {
    strip(html) {
      const tempDiv = document.createElement('DIV')
      tempDiv.innerHTML = html
      return tempDiv.textContent
    },
    onPaste(e) {
      this.$refs.editable.textContent = this.strip(e.target.textContent)
    },
    onInput(e) {
      this.$emit('input', this.strip(e.target.textContent))
    }
  }
}
</script>

<style>
.content-editable {
  display: inline-block;
  min-width: 10px;
}

.content-editable:focus {
  outline: none;
}

.content-editable:empty::after {
  display: block;
  content: attr(placeholder);
  opacity: 0.75;
}
</style>
