export const articleTypes = [
  'Service',
  'Produit',
  'Session',
  'Pack',
  'Cure',
  'Prépaiement',
  'Bon Cadeau',
  'Forfait à la minute'
]
export const articleTypesSelectMode = [
  { text: 'Services', value: '1' },
  { text: 'Produits', value: '2' },
  { text: 'Forfaits à la séance', value: '3' },
  { text: 'Forfaits', value: '4' },
  { text: 'Cures', value: '5' },
  { text: 'Pré-paiement', value: '6' },
  { text: 'Bon Cadeau', value: '7' },
  { text: 'Forfaits à la minute', value: '8' }
]
