<template>
  <div class="d-flex align-center">
    <strong
      v-if="blockData.data.required && (fillable || editable)"
      style="color: red; margin-right: 5px"
    >
      *
    </strong>
    <TextEditor v-model="blockData.data.text" :editable="editable" />
    <v-text-field
      v-model="block.data.value"
      class="ml-6"
      :disabled="!fillable"
    />
  </div>
</template>

<script>
import TextEditor from '../../helpers/TextEditor'

export default {
  name: 'InputField',
  components: { TextEditor },
  props: {
    block: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    fillable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      blockData: this.block
    }
  },
  watch: {
    'block.data.value'(newVal) {
      if (!this.block.data.required) {
        this.block.data.isValid = true
        return
      }

      if (!newVal) {
        this.block.data.isValid = false
      } else {
        this.block.data.isValid = true
      }
    }
  },
  created() {
    if (!this.block.data.text) {
      this.$set(this.block.data, 'text', 'Entrez un texte')
    }

    if (!this.block.data.value) {
      this.$set(this.block.data, 'value', '')
    }

    this.$set(this.block.data, 'isValid', !this.block.data.required)
  }
}
</script>

<style scoped></style>
