import ParagraphBlock from './blocks/paragraph'
import TitleBlock from './blocks/title'
import CheckboxBlock from './blocks/checkbox'
import YesNoBlock from './blocks/yesno'
import InputFieldBlock from './blocks/inputfield'

export default {
  title: new TitleBlock(),
  paragraph: new ParagraphBlock(),
  checkbox: new CheckboxBlock(),
  yesno: new YesNoBlock(),
  inputfield: new InputFieldBlock()
}
