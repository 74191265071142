<template>
  <v-card v-if="linkShopsArticle">
    <v-card-title class="cardTitle">
      {{ $t('articles.shopsLinkedToArticles') }} :
      <span class="font-weight-bold ml-5">
        {{ controli18n($i18n.locale, linkShopsArticle.name) }}
      </span>
    </v-card-title>
    <v-divider />

    <v-card-text>
      <div class="d-flex align-center mb-5" style="column-gap: 10px">
        <v-select
          v-model="selectedStatus"
          :items="tabStatus"
          :label="$t('database.tables.status')"
          background-color="white"
          outlined
          hide-details
          dense
          style="width: 175px"
          class="customWidth"
          @change="search"
        >
          <template v-slot:selection>
            <div class="d-flex align-center">
              <v-icon
                :color="
                  selectedStatus === 'all'
                    ? 'black'
                    : selectedStatus === 'onLine'
                    ? 'green'
                    : 'red'
                "
              >
                mdi-checkbox-blank-circle
              </v-icon>
              <span class="ml-2" style="white-space: nowrap">
                {{ $icones[selectedStatus].title }}
              </span>
            </div>
          </template>
          <template v-slot:item="{ item }">
            <v-icon
              :color="
                item === 'all' ? 'black' : item === 'onLine' ? 'green' : 'red'
              "
            >
              mdi-checkbox-blank-circle
            </v-icon>
            <span class="ml-2">{{ $icones[item].title }}</span>
          </template>
        </v-select>

        <v-text-field
          v-model="selectedName"
          :label="$t('other.name')"
          background-color="white"
          outlined
          hide-details
          dense
          @keypress.enter="search"
        />

        <v-text-field
          v-model="selectedZipcode"
          :label="$t('database.tables.postalCode')"
          background-color="white"
          outlined
          hide-details
          dense
          @keypress.enter="search"
        />

        <v-btn dark @click="search">{{ $t('other.research') }}</v-btn>
      </div>

      <v-row no-gutters>
        <v-col cols="6" class="text-center">
          <v-btn
            class="px-15"
            color="primary"
            @click="linkAllShops"
            elevation="2"
          >
            {{ $t('icons.all') }}
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-btn
            class="px-15"
            color="primary"
            @click="unlinkAllShops"
            elevation="2"
          >
            {{ $t('geoGroup.none') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-card class="ma-6">
        <v-card>
          <v-data-table
            :items="shops"
            :headers="onlineShopsHeaders"
            group-by="grp.name"
            hide-default-footer
            fixed-header
            height="55vh"
            :items-per-page="itemsPerPage"
            :page.sync="pagination"
            @page-count="pageCount = $event"
          >
            <template v-slot:item.status="{ item }">
              <v-icon
                v-if="!isLinked(item)"
                @click="linkItem(item)"
                color="red"
              >
                mdi-checkbox-blank-circle
              </v-icon>
              <v-icon v-else @click="unlinkItem(item)" color="green">
                mdi-checkbox-blank-circle
              </v-icon>
            </template>

            <template
              v-slot:[`group.header`]="{ group, toggle, isOpen, items }"
            >
              <td
                :colspan="onlineShopsHeaders.length + 1"
                class="grey darken-2"
              >
                <div class="d-inline">
                  <v-btn icon @click="toggle">
                    <v-icon color="white" v-if="isOpen">mdi-chevron-up</v-icon>
                    <v-icon color="white" v-if="!isOpen">
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                  <span
                    v-if="items.length === 1"
                    style="color: white; font-weight: bold"
                  >
                    {{ group ? group : $t('settings.colors.others') }}
                    -
                    {{ items.length }}
                    {{ $t('database.shops') }}
                  </span>
                  <span v-else style="color: white; font-weight: bold">
                    {{ group ? group : $t('settings.colors.others') }} -
                    {{ items.length }}
                    {{ $t('database.shops') }}
                  </span>
                </div>
                <v-divider v-if="!isOpen" dark></v-divider>
              </td>
            </template>
          </v-data-table>
        </v-card>

        <v-overlay
          class="d-flex justify-center align-center"
          v-if="loading"
          absolute
        >
          <v-progress-circular indeterminate />
        </v-overlay>
      </v-card>

      <div class="d-flex justify-center">
        <v-pagination
          v-if="pageCount > 1"
          v-model="pagination"
          :length="pageCount"
        />
      </div>
      <div class="d-flex justify-center mt-2">
        <div>
          <v-select
            v-model="itemsPerPage"
            :items="selectAbItemsPerPage"
            background-color="white"
            outlined
            hide-details
            dense
            class="customWidth"
          />
        </div>
      </div>
    </v-card-text>

    <v-divider />
    <v-card-actions>
      <v-btn text @click="$emit('close')">
        {{ $t('other.close') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { controli18n } from '@/helpers/langs'
import icones from '@/helpers/icones'

export default {
  name: 'linkShopsToArticle',
  props: {
    linkShopsArticle: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    shops: [],
    loading: false,

    tabStatus: ['all', 'onLine', 'offLine'],
    selectedStatus: 'all',
    selectedName: null,
    selectedZipcode: null,

    itemsPerPage: 10,
    pagination: 1,
    pageCount: 0
  }),
  computed: {
    onlineShops() {
      return this.$store.getters['shop/fusionShop']({
        active: true,
        isOnline: true,
        onLineType: []
      })
    },
    onlineShopsHeaders() {
      return [
        {
          text: this.$t('database.tables.status'),
          value: 'status',
          divider: true,
          sortable: false,
          align: 'center'
        },
        {
          text: this.$t('other.name'),
          value: 'name',
          divider: true,
          sortable: false,
          align: 'start'
        },
        {
          text: this.$t('database.tables.postalCode'),
          value: 'zipcode',
          divider: true,
          sortable: false,
          align: 'center'
        }
      ]
    },
    selectAbItemsPerPage() {
      return [
        10,
        25,
        50,
        100,
        {
          text: this.$t('other.all'),
          value: -1
        }
      ]
    },
    $icones() {
      return icones().getIcones()
    }
  },
  methods: {
    controli18n,
    isLinked(shop) {
      const articleIndex = shop.articles.findIndex(
        a => a.id === this.linkShopsArticle.id
      )
      return articleIndex !== -1
    },
    search() {
      let shops = this.onlineShops

      if (this.selectedStatus === 'onLine') {
        const shopsToKeep = []
        shops.forEach(art => {
          if (this.isLinked(art)) {
            shopsToKeep.push(art)
          }
        })

        shops = shops.filter(art => shopsToKeep.includes(art))
      } else if (this.selectedStatus === 'offLine') {
        const shopsToKeep = []
        shops.forEach(art => {
          if (!this.isLinked(art)) {
            shopsToKeep.push(art)
          }
        })

        shops = shops.filter(art => shopsToKeep.includes(art))
      }

      if (this.selectedName) {
        shops = shops.filter(art =>
          art.name.toUpperCase().includes(this.selectedName.toUpperCase())
        )
      }

      if (this.selectedZipcode) {
        shops = shops.filter(art => art.zipcode.includes(this.selectedZipcode))
      }

      this.shops = shops
    },

    async linkItem(shop) {
      this.loading = true
      try {
        await this.$store.dispatch('shop/linkArticle', {
          shop,
          article: this.linkShopsArticle
        })
        this.shops = this.onlineShops
        this.search()
      } catch (err) {
        console.log('err :>> ', err)
      }
      this.loading = false
    },
    async unlinkItem(shop) {
      this.loading = true
      try {
        await this.$store.dispatch('shop/unlinkArticle', {
          shop,
          article: this.linkShopsArticle
        })
        this.shops = this.onlineShops
        this.search()
      } catch (err) {
        console.log('err :>> ', err)
      }
      this.loading = false
    },
    async linkAllShops() {
      this.loading = true
      await this.$store.dispatch('articles/linkAllShops', this.linkShopsArticle)
      this.shops = this.onlineShops
      this.search()
      this.loading = false
    },
    async unlinkAllShops() {
      this.loading = true
      await this.$store.dispatch(
        'articles/unlinkAllShops',
        this.linkShopsArticle
      )
      this.shops = this.onlineShops
      this.search()
      this.loading = false
    }
  },
  mounted() {
    this.shops = this.onlineShops
  }
}
</script>

<style scoped></style>
